<template>
    <div>
        <div class="right-menu shipping-right">

            <subHeader pageName="SUPPORT MEMBERS" />

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <moduleSearch :moduleName="'support_member'" :disable="tourIsActive" ref="module_search"/>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="supportTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == supportMembersList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Designation">Designation</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Platform">Platform</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="supportMembersLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="supportMembersList && supportMembersList.length == 0">
                                        <td colspan="7" style="text-align:center">
                                            <p class="mb-0">
                                                {{ supportMembersSearchKeyword ? `No support members found matching '${supportMembersSearchKeyword}'` : 'No Support Members Available' }}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr v-for="(listedSupportMember, index) in supportMembersList" :key="index">

                                        <!-- checkbox & image block starts here -->
                                        <td @click="previewSupportMember(listedSupportMember,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label 
                                                    class="ctmTableCheckContainer" 
                                                    data-open="false" 
                                                    @click.prevent=" listedSupportMember.id != -1 ? checkedItemHandle(listedSupportMember.id) : ''"
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="listedSupportMember.id" :disabled="tourIsActive || listedSupportMember.id == -1" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                                <!-- for saved member -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-if="listedSupportMember.id != -1">
                                                    <img 
                                                        :src="listedSupportMember.image ? listedSupportMember.image_src : '/images/no_image.png'" 
                                                        alt=""
                                                        v-if="listedSupportMember.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(listedSupportMember) ? generateUrl(listedSupportMember) : '/images/no_image.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                                <!-- for unsaved member -->
                                                <div class="ctmDataTableImgBox" data-open="true" v-else>
                                                    <img 
                                                        :src="listedSupportMember.addImage ? listedSupportMember.addImage_src : '/images/no_image.png'" 
                                                        alt=""
                                                        v-if="listedSupportMember.id == -1"
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                    <img
                                                        :src="generateUrl(listedSupportMember) ? generateUrl(listedSupportMember) : '/images/no_image.png'"
                                                        alt=""
                                                        data-open="true"
                                                        @error="handleImageError"
                                                        v-else
                                                    >
                                                </div>
                                            </div>
                                        </td>
                                        <!-- checkbox & image block ends here -->

                                        <!-- member name block starts here -->
                                        <td class="mainTableTitle" @click="previewSupportMember(listedSupportMember,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="member-name-field-3">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a support member name"
                                                    v-model="listedSupportMember.name" 
                                                    @focus="supportMemberSelection(listedSupportMember)" 
                                                    @blur="handleOTGUpdate(listedSupportMember,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- member name block ends here -->

                                        <!-- member designation block starts here -->
                                        <td @click="previewSupportMember(listedSupportMember,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedSupportMember.designation ? listedSupportMember.designation.length > 15 ? listedSupportMember.designation.substr(0, 15) + "..." : listedSupportMember.designation : '--' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- member designation block ends here -->

                                        <!-- member designation block starts here -->
                                        <td @click="previewSupportMember(listedSupportMember,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    {{ listedSupportMember.platform == 'whatsapp' ? 'Whatsapp' : 'Messenger' }}
                                                </p>
                                            </div>
                                        </td>
                                        <!-- member designation block ends here -->

                                        <!-- action block starts here -->
                                        <td @click="previewSupportMember(listedSupportMember,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(listedSupportMember.id) && deleteLoader" data-open="true">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button 
                                                    class="delFunction" 
                                                    @click.prevent="deleteSupportMemberHandle(listedSupportMember,index)" 
                                                    :disabled="tourIsActive" 
                                                    data-open="false"
                                                >
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update support member form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="support-member-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="member-name-field-2">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a support member name" 
                                                    v-model="previewForm.name"
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active">Support Member Info</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="supportMemberIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-support-member-btn" v-else>
                                        <button 
                                            form="support-member-information" 
                                            type="submit"
                                            :disabled="tourIsActive"
                                        >
                                            {{ previewForm.id == -1 ? 'Save' : 'Update' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="supportMemberFormObserver">
                                <div class="productTableInfoBlock">
                                    <div class="productTableInfoWhiteBox">

                                        <!-- image for add support member -->
                                        <div class="uploadNewUserImg" id="support-member-image" v-if="this.previewForm.id == -1">
                                            <div class="userDetailsImg">
                                                <img 
                                                    :src="previewForm.addImage ? previewForm.addImage_src ? previewForm.addImage_src : base_image_url + settings.store_id + '/support/' + previewForm.addImage : '/images/customer_noImage.png' " 
                                                    alt=""
                                                    @error="handleImageError"
                                                />
                                            </div>
                                            <div class="uploadImgFile" id="add-support-member-image">
                                                <input 
                                                    type="file" 
                                                    ref="addPreviewFileInput" 
                                                    @change.prevent="changeAddImage" 
                                                    accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                    :disabled="tourIsActive"
                                                >
                                                <button>
                                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <span 
                                            class="text-danger mx-auto" 
                                            style="text-align:center; display:block" 
                                            v-if="this.previewForm.id == -1 && imageValidations.imgIsRequired"
                                        >
                                            Image field is required
                                        </span>
                                        
                                        <!-- image for update support member -->
                                        <div class="uploadNewUserImg" id="support-member-image" v-if="this.previewForm.id != -1">
                                            <div class="userDetailsImg">
                                                <img 
                                                    :src="previewForm.image ? previewForm.image_src ? previewForm.image_src : base_image_url + settings.store_id + '/support/' + previewForm.image : '/images/customer_noImage.png' " 
                                                    alt=""
                                                    @error="handleImageError"
                                                />
                                            </div>
                                            <div class="uploadImgFile">
                                                <input 
                                                    type="file" 
                                                    ref="previewFileInput" 
                                                    @change.prevent="handleChangeEditImage" 
                                                    accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                    :disabled="tourIsActive"
                                                >
                                                <button>
                                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C6.04586 19.4985 5.85015 19.4637 5.66822 19.3996L11.4745 13.7138L11.558 13.6431C11.8493 13.4307 12.2588 13.4543 12.5238 13.7139L14.6028 15.7501L15.6636 14.6893L13.5732 12.6423L13.4448 12.5257C12.5666 11.7877 11.2581 11.8265 10.4251 12.6421L4.60312 18.3423C4.53629 18.157 4.49987 17.9571 4.49987 17.7487V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM17.503 8.75161C17.503 7.50791 16.4947 6.49969 15.251 6.49969C14.0073 6.49969 12.9991 7.50791 12.9991 8.75161C12.9991 9.99531 14.0073 11.0035 15.251 11.0035C16.4947 11.0035 17.503 9.99531 17.503 8.75161ZM14.499 8.75161C14.499 8.33626 14.8357 7.99956 15.251 7.99956C15.6664 7.99956 16.0031 8.33626 16.0031 8.75161C16.0031 9.16695 15.6664 9.50366 15.251 9.50366C14.8357 9.50366 14.499 9.16695 14.499 8.75161ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <span 
                                            class="text-danger mx-auto" 
                                            style="text-align:center; display:block" 
                                            v-if="this.previewForm.id != -1 && imageValidations.imgIsRequired"
                                        >
                                            Image field is required
                                        </span>

                                        <form id="support-member-information" @submit.prevent="validate()">
                                            <div class="tableFromFieldBox">
                                                <label for="support-member-name-field">Name<span>*</span></label>
                                                <ValidationProvider 
                                                    name="name" 
                                                    :rules="{ required: true, max:250 }" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="support-member-name-field" 
                                                            id="support-member-name-field" 
                                                            placeholder="Name"
                                                            v-model="previewForm.name" 
                                                            :disabled="tourIsActive"
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="support-member-designation-field">Designation<span>*</span></label>
                                                <ValidationProvider 
                                                    name="designation" 
                                                    :rules="{ required: true, max:250 }" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="support-member-designation-field" 
                                                            id="support-member-designation-field" 
                                                            placeholder="Designation"
                                                            v-model="previewForm.designation" 
                                                            :disabled="tourIsActive"
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="support-member-platform-field">Platform<span>*</span></label>
                                                <ValidationProvider 
                                                    name="platform" 
                                                    rules="required" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="vueCtmSelectBlock">
                                                        <select 
                                                            name="support-member-platform-field" 
                                                            id="support-member-platform-field" 
                                                            v-model="previewForm.platform" 
                                                            :disabled="tourIsActive"
                                                        >
                                                            <option value="whatsapp">Whatsapp</option>
                                                            <option value="messenger">Messenger</option>
                                                        </select>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" id="support-member-number" v-if="previewForm.platform == 'whatsapp'">
                                                <label for="support-member-number-field">Number<span>*</span></label>
                                                <div class="tableFromFieldItem">
                                                    <contactInput
                                                        :contactNo="previewForm.number"
                                                        :placeHolder="'Number'"
                                                        :setValidations="setMobileNoFieldValidations"
                                                        :disabled="tourIsActive"
                                                        ref="contactInputfield"
                                                    />
                                                    <span class="text-danger" v-if="!countryIsSelected && contactFieldTouched">
                                                        Select a country from the dropdown menu or add the country code before the mobile no.
                                                    </span>
                                                    <span class="text-danger" v-else-if="!contactIsValid && contactFieldTouched">
                                                        Enter a valid mobile no.
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="tableFromFieldBox" id="support-member-messenger-id" v-if="previewForm.platform == 'messenger'">
                                                <label for="support-member-messenger-id-field">Messenger ID<span>*</span></label>
                                                <ValidationProvider 
                                                    name="messenger id" 
                                                    :rules="previewForm.platform == 'messenger' ? 'required|max:250' : '' " 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            name="support-member-messenger-id-field" 
                                                            id="support-member-messenger-id-field" 
                                                            v-model="previewForm.platform_id" 
                                                            :disabled="tourIsActive"
                                                        >
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="valid-from-field">Available From<span>*</span></label>
                                                <ValidationProvider 
                                                    name="available from" 
                                                    rules="required" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="time" 
                                                            id="valid-from-field"
                                                            name="valid-from-field" 
                                                            class="form-control" 
                                                            v-model="previewForm.available_from" 
                                                            @input="checkTimeFormat()"
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="valid-to-field">Available To<span>*</span></label>
                                                <ValidationProvider 
                                                    name="available to" 
                                                    rules="required" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="time" 
                                                            id="valid-to-field"
                                                            name="valid-to-field" 
                                                            class="form-control" 
                                                            v-model="previewForm.available_to" 
                                                            @input="checkTimeFormat()"
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- add/update support member form ends here -->

                    </div>
                    <!-- data table ends here -->

                    <!--bottom action bar starts here-->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != supportMembersList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 ml-4" @click.prevent="chatSupportBulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--bottom action bar ends here-->

                </div>
            </div>

        </div>

        <!--vue tour-->
        <v-tour name="chatSupportTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import _ from 'lodash';
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import { mapGetters } from 'vuex';
import { MessageBox, Message, Loading } from 'element-ui'
import loaderBtn from "@/components/loaderBtn.vue";
import contactInput from "@/components/contactInput.vue";
import moduleSearch from "@/components/ModuleSearch/index.vue";

export default {
    metaInfo() {
        return {
            title: 'Support Members | YeetCommerce',
        };
    },
    data(){
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            //support start
            supportMembersList: [],
            supportMembersLoading: true,
            supportMemberSaved: false,
            supportMemberIsSaving: false,
            previewForm:{
                id: '',
                designation: '',
                platform: 'whatsapp',
                platform_id: '',
                number: '',
                available_from: '',
                available_to: '',
                isTouched: false,
                image: null,
                image_src: '',
                addImage: null,
                addImage_src: '',
            },
            defaultPreviewForm:{
                id: '',
                designation: '',
                platform: 'whatsapp',
                platform_id: '',
                number: '',
                available_from: '',
                available_to: '',
                isTouched: false,
                image: null,
                image_src: '',
                addImage: null,
                addImage_src: '',
            },
            tempSupportMember:{
                id: '',
                designation: '',
                platform: 'whatsapp',
                platform_id: '',
                number: '',
                available_from: '',
                available_to: '',
                isTouched: false,
                image: null,
                image_src: '',
                addImage: null,
                addImage_src: '',
            },
            tempPreviewSupportMember:{
                id: '',
                designation: '',
                platform: 'whatsapp',
                platform_id: '',
                number: '',
                available_from: '',
                available_to: '',
                isTouched: false,
                image: null,
                image_src: '',
                addImage: null,
                addImage_src: '',
            },
            //support end

            //checked items start
            checkedItems: [],
            deletingItems: [],
            deleteLoader: false,
            isMasterSearched: false,
            //checked items end

            //image validations start
            imageValidations:{
                imgIsRequired: false,
                imgInvalidFormat: false,
                isNotImg: false,
            },
            //image validations end

            //mobile no. properties start
            contactIsValid: true,
            countryIsSelected: false,
            contactFieldTouched: false,
            selectedContactObj: {},
            selectedContactCountry: [],
            //mobile no. properties end

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //chat support steps
                {
                    target: '#module-search',
                    content: `<strong>Search Support Member</strong><br>It enables you to search specific support members.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#preview-record',
                    content: `<strong>Support Members Table</strong><br>You can preview and edit the added support members by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Support Member</strong><br>By clicking this button, you can add a new support member and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#support-member-details-preview',
                    content: `<strong>Support Member Details Form</strong><br>This form can be used to add details about the support member and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#add-support-member-image',
                    content: `<strong>Support Member's Image</strong><br>Image of the support member can be added using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#support-member-name-field',
                    content: `<strong>Support Member's Name Field</strong><br>name of the support member can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#support-member-designation-field',
                    content: `<strong>Support Members's Designation Field</strong><br>designation of the support member can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#support-member-platform-field',
                    content: `<strong>Support Members's Platform Field</strong><br>platform of the support member can be selected using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#support-member-number',
                    content: `<strong>Support Members's Number Field</strong><br>number of the support member can be added here if selected platform is whatsapp.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#valid-from-field',
                    content: `<strong>Support Members's Availability</strong><br>available from time of the support member can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#valid-to-field',
                    content: `<strong>Support Members's Availability</strong><br>available to time of the support member can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#member-name-field-3',
                    content: `<strong>Support Member's Name Field</strong><br>name of the support member can also be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#member-name-field-2',
                    content: `<strong>Support Member's Name Field</strong><br>name of the support member can also be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'right'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-support-member-btn',
                    content: `<strong>Save Support Member Details</strong><br>Added support member details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive: false,
            //tour end
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn,
        contactInput,
        moduleSearch,
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            storedSupportMembers:'support_members_module/members',
            supportMembersCurrentPage:'support_members_module/currentPage',
            supportMembersLastPage:'support_members_module/lastPage',
            supportMembersSearchKeyword:'support_members_module/keyword',
            isPending:'support_members_module/isPending',
        }),
    },
    watch:{
        'storedSupportMembers':{
            handler:function(value){

                this.supportMembersLoading = false;

                if(this.previewForm.id == -1){
                    
                    this.supportMembersList = _.cloneDeep(value);

                    this.supportMembersList.unshift(this.previewForm);

                }else if(this.tempPreviewSupportMember.id != ''){
                    
                    this.supportMembersList = _.cloneDeep(value);

                    this.supportMembersList[this.previewForm.index] = this.previewForm;

                }else{

                    this.supportMembersList = _.cloneDeep(value);

                }

                if(this.isMasterSearched){

                    this.previewSearchedSupportMember(this.supportMembersList[0],0);

                    this.isMasterSearched = false;
                }

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.name || value.designation || value.platform || 
                value.available_from || value.available_to)){

                    value.isTouched = true;

                }else{

                    value.isTouched = false;

                }

            },deep:true
        }
    },
    methods:{

        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.supportTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.supportMembersCurrentPage != this.supportMembersLastPage){

                    this.$store.dispatch('support_members_module/fetchStoreSupportMembersRequest',{size:10, page: this.supportMembersCurrentPage + 1, loadData: 'more'});

                }

            }

        },
        //infinite scroll end

        //OTG update start
        supportMemberSelection(listedSupportMember){

            this.tempSupportMember = _.cloneDeep(listedSupportMember);

        },
        async handleOTGUpdate(listedSupportMember,index){

            if(this.previewForm.id == ''){

                if(listedSupportMember.id != -1){

                    if(this.tempSupportMember.name != listedSupportMember.name){

                        if(listedSupportMember.name.trim().length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The support member's name field is required.",
                            });

                            this.supportMembersList[index].name = this.tempSupportMember.name;

                            return

                        }

                        if(listedSupportMember.name.trim().length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The support member's name may not be greater than 250 characters.",
                            });

                            this.supportMembersList[index].name = this.tempSupportMember.name;

                            return

                        }

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let formData = new FormData();

                            formData.append('id',listedSupportMember.id);
                            formData.append('name',listedSupportMember.name.trim());
                            formData.append('designation',listedSupportMember.designation);
                            formData.append('platform',listedSupportMember.platform);

                            if(listedSupportMember.platform == 'whatsapp'){

                                formData.append('number',listedSupportMember.number.replace(/\s/g,''));

                            }

                            if(listedSupportMember.platform == 'messenger'){

                                formData.append('platform_id',listedSupportMember.platform_id);

                            }

                            formData.append('available_from',listedSupportMember.available_from);
                            formData.append('available_to',listedSupportMember.available_to);

                            let loader = Loading.service({
                                text: "The support member is being updated. Please wait!",
                                fullscreen: true,
                            });

                            try{
                                let res = await this.$axios.post('/chat-support/update',formData);
                                if(res.data.status_code == "1272"){

                                    this.$notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: res.data.message,
                                    });

                                    let teamMember = res.data.team_member

                                    let payload = {
                                        index: index,
                                        updatedMember: teamMember
                                    }

                                    this.$store.commit('support_members_module/update_support_member_list',payload);

                                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                    this.$store.commit('gallery_module/CLEAR_MODULE');

                                }
                            }catch(error){

                                this.supportMembersList[index].name = this.tempSupportMember.name;

                                this.tempSupportMember = null;

                                if(error.response){

                                    if(error.response.data.error.id){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.id[0],
                                        });

                                    }else if(error.response.data.error.includes('storage limit has been reached')){
                        
                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error,
                                        });

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                            }finally{

                                loader.close();

                            }

                        }).catch(() => {

                            this.supportMembersList[index].name = this.tempSupportMember.name;

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes discarded successfully.",
                            });

                            this.tempSupportMember = null;

                        })

                    }

                }

            }else{

                if(listedSupportMember.id != -1){
                
                    if(listedSupportMember.id != this.previewForm.id){

                        if(this.tempSupportMember.name != listedSupportMember.name){

                            if(listedSupportMember.name.trim().length == 0){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The support member's name field is required.",
                                });

                                this.supportMembersList[index].name = this.tempSupportMember.name;

                                return

                            }

                            if(listedSupportMember.name.trim().length > 250){

                                this.$notify({
                                    type: 'warning',
                                    title: 'Alert',
                                    message: "The support member's name may not be greater than 250 characters.",
                                });

                                this.supportMembersList[index].name = this.tempSupportMember.name;

                                return

                            }

                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(async () => {

                                let formData = new FormData();

                                formData.append('id',listedSupportMember.id);
                                formData.append('name',listedSupportMember.name.trim());
                                formData.append('designation',listedSupportMember.designation);
                                formData.append('platform',listedSupportMember.platform);

                                if(listedSupportMember.platform == 'whatsapp'){

                                    formData.append('number',listedSupportMember.number.replace(/\s/g,''));

                                }

                                if(listedSupportMember.platform == 'messenger'){

                                    formData.append('platform_id',listedSupportMember.platform_id);

                                }

                                formData.append('available_from',listedSupportMember.available_from);
                                formData.append('available_to',listedSupportMember.available_to);

                                let loader = Loading.service({
                                    text: "The support member is being updated. Please wait!",
                                    fullscreen: true,
                                });

                                try{
                                    let res = await this.$axios.post('/chat-support/update',formData);
                                    if(res.data.status_code == "1272"){

                                        this.$notify({
                                            type: 'success',
                                            title: 'Success',
                                            message: res.data.message,
                                        });

                                        let teamMember = res.data.team_member

                                        let payload = {
                                            index: index,
                                            updatedMember: teamMember
                                        }

                                        this.$store.commit('support_members_module/update_support_member_list',payload);

                                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                                        this.$store.commit('gallery_module/CLEAR_MODULE');

                                    }
                                }catch(error){

                                    this.supportMembersList[index].name = this.tempSupportMember.name;

                                    this.tempSupportMember = null;

                                    if(error.response){

                                        if(error.response.data.error.id){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.id[0],
                                            });

                                        }else if(error.response.data.error.includes('storage limit has been reached')){
                            
                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error,
                                            });

                                        }else{

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.message,
                                            });

                                        }

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.message,
                                        });

                                    }

                                }finally{

                                    loader.close();

                                }

                            }).catch(() => {

                                this.supportMembersList[index].name = this.tempSupportMember.name;

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes discarded successfully.",
                                });

                                this.tempSupportMember = null;

                            })

                        }

                    }
                
                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewSupportMember(listedSupportMember,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){

                    if(listedSupportMember.id != -1){
                    
                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.supportMembersList.findIndex((member) => member.id == -1);

                                if(index != -1){

                                    this.supportMembersList.splice(index,1); 

                                }

                                if(!this.supportMemberSaved){

                                    let tempIndex = this.supportMembersList.findIndex(x=>x.id == this.tempPreviewSupportMember.id);

                                    if(tempIndex != -1){

                                        this.supportMembersList[tempIndex] = this.tempPreviewSupportMember;

                                    }

                                }

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = '';

                                }

                                let member = listedSupportMember;

                                this.previewForm = member;

                                if(this.previewForm.available_from){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_from}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_from = formattedTime;

                                    let availableFromTime = this.previewForm.available_from.split(" ");

                                    this.previewForm.available_from = availableFromTime[0];

                                }

                                if(this.previewForm.available_to){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_to}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_to = formattedTime;

                                    let availableToTime = this.previewForm.available_to.split(" ");

                                    this.previewForm.available_to = availableToTime[0];

                                }

                                this.previewForm.index = i;

                                this.previewForm.image_src = null;
                                this.previewForm.image = member.image;

                                this.checkedItems = [];

                                this.supportMemberIsSaving = false;

                                this.tempPreviewSupportMember = _.cloneDeep(member);

                            }).catch(() => {})

                        }else{

                            let index = this.supportMembersList.findIndex((member) => member.id == -1);
                                
                            if(index != -1){
                
                                this.supportMembersList.splice(index, 1);
                
                            }

                            if(!this.supportMemberSaved && (this.tempPreviewSupportMember.id != '' && this.tempPreviewSupportMember.id != listedSupportMember.id)){
                                
                                let tempIndex = this.supportMembersList.findIndex(x=>x.id == this.tempPreviewSupportMember.id);
                                
                                if(tempIndex != -1){

                                    this.supportMembersList[tempIndex] = this.tempPreviewSupportMember;

                                }
                
                            }

                            if(this.tempPreviewSupportMember.id != '' && this.tempPreviewSupportMember.id == listedSupportMember.id){

                                let tempIndex = this.supportMembersList.findIndex(x=>x.id == this.tempPreviewSupportMember.id);
                                
                                this.supportMembersList[tempIndex] = _.cloneDeep(this.storedSupportMembers[tempIndex]);

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = '';

                                }

                                let member = _.cloneDeep(this.storedSupportMembers[tempIndex]);

                                this.previewForm = this.storedSupportMembers[tempIndex];

                                if(this.previewForm.available_from){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_from}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_from = formattedTime;

                                    let availableFromTime = this.previewForm.available_from.split(" ");

                                    this.previewForm.available_from = availableFromTime[0];

                                }

                                if(this.previewForm.available_to){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_to}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_to = formattedTime;

                                    let availableToTime = this.previewForm.available_to.split(" ");

                                    this.previewForm.available_to = availableToTime[0];

                                }

                                this.previewForm.index = i;

                                this.previewForm.image_src = null;
                                this.previewForm.image = member.image;

                                this.checkedItems = [];

                                this.supportMemberIsSaving = false;

                                this.tempPreviewVendor = _.cloneDeep(member);

                            }else{

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = '';

                                }

                                let member = listedSupportMember;

                                this.previewForm = member;

                                if(this.previewForm.available_from){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_from}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_from = formattedTime;

                                    let availableFromTime = this.previewForm.available_from.split(" ");

                                    this.previewForm.available_from = availableFromTime[0];

                                }

                                if(this.previewForm.available_to){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_to}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_to = formattedTime;

                                    let availableToTime = this.previewForm.available_to.split(" ");

                                    this.previewForm.available_to = availableToTime[0];

                                }

                                this.previewForm.index = i;

                                this.previewForm.image_src = null;
                                this.previewForm.image = member.image;

                                this.checkedItems = [];

                                this.supportMemberIsSaving = false;

                                this.tempPreviewSupportMember = _.cloneDeep(member);

                            }

                        }
                    
                    }

                }

            }else{

                if(!this.tourIsActive){
                
                    if(listedSupportMember.id != -1){
                    
                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.supportMembersList.findIndex((member) => member.id == -1);

                                if(index != -1){

                                    this.supportMembersList.splice(index,1); 

                                }

                                if(!this.supportMemberSaved){

                                    let tempIndex = this.supportMembersList.findIndex(x=>x.id == this.tempPreviewSupportMember.id);

                                    if(tempIndex != -1){

                                        this.supportMembersList[tempIndex] = this.tempPreviewSupportMember;

                                    }

                                }

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = '';

                                }

                                let member = listedSupportMember;

                                this.previewForm = member;

                                if(this.previewForm.available_from){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_from}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_from = formattedTime;

                                    let availableFromTime = this.previewForm.available_from.split(" ");

                                    this.previewForm.available_from = availableFromTime[0];

                                }

                                if(this.previewForm.available_to){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_to}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_to = formattedTime;

                                    let availableToTime = this.previewForm.available_to.split(" ");

                                    this.previewForm.available_to = availableToTime[0];

                                }

                                this.previewForm.index = i;

                                this.previewForm.image_src = null;
                                this.previewForm.image = member.image;

                                this.checkedItems = [];

                                this.supportMemberIsSaving = false;

                                this.tempPreviewSupportMember = _.cloneDeep(member);

                            }).catch(() => {})

                        }else{

                            let index = this.supportMembersList.findIndex((member) => member.id == -1);
                                
                            if(index != -1){
                
                                this.supportMembersList.splice(index, 1);
                
                            }

                            if(!this.supportMemberSaved && (this.tempPreviewSupportMember.id != '' && this.tempPreviewSupportMember.id != listedSupportMember.id)){
                                
                                let tempIndex = this.supportMembersList.findIndex(x=>x.id == this.tempPreviewSupportMember.id);
                
                                if(tempIndex != -1){

                                    this.supportMembersList[tempIndex] = this.tempPreviewSupportMember;

                                }
                
                            }

                            if(this.tempPreviewSupportMember.id != '' && this.tempPreviewSupportMember.id == listedSupportMember.id){

                                let tempIndex = this.supportMembersList.findIndex(x=>x.id == this.tempPreviewSupportMember.id);
                                
                                this.supportMembersList[tempIndex] = _.cloneDeep(this.storedSupportMembers[tempIndex]);

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = '';

                                }

                                let member = _.cloneDeep(this.storedSupportMembers[tempIndex]);

                                this.previewForm = this.storedSupportMembers[tempIndex];

                                if(this.previewForm.available_from){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_from}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_from = formattedTime;

                                    let availableFromTime = this.previewForm.available_from.split(" ");

                                    this.previewForm.available_from = availableFromTime[0];

                                }

                                if(this.previewForm.available_to){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_to}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_to = formattedTime;

                                    let availableToTime = this.previewForm.available_to.split(" ");

                                    this.previewForm.available_to = availableToTime[0];

                                }

                                this.previewForm.index = i;

                                this.previewForm.image_src = null;
                                this.previewForm.image = member.image;

                                this.checkedItems = [];

                                this.supportMemberIsSaving = false;

                                this.tempPreviewVendor = _.cloneDeep(member);

                            }else{

                                this.resetImageValidations();

                                if(this.$refs.previewFileInput){

                                    this.$refs.previewFileInput.value = '';

                                }

                                let member = listedSupportMember;

                                this.previewForm = member;

                                if(this.previewForm.available_from){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_from}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_from = formattedTime;

                                    let availableFromTime = this.previewForm.available_from.split(" ");

                                    this.previewForm.available_from = availableFromTime[0];

                                }

                                if(this.previewForm.available_to){

                                    const time = new Date(`1970-01-01 ${listedSupportMember.available_to}`);

                                    // Format the time using the desired format
                                    const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                                    this.previewForm.available_to = formattedTime;

                                    let availableToTime = this.previewForm.available_to.split(" ");

                                    this.previewForm.available_to = availableToTime[0];

                                }

                                this.previewForm.index = i;

                                this.previewForm.image_src = null;
                                this.previewForm.image = member.image;

                                this.checkedItems = [];

                                this.supportMemberIsSaving = false;

                                this.tempPreviewSupportMember = _.cloneDeep(member);

                            }

                        }
                
                    }
                    
                }

            }

        },
        previewSearchedSupportMember(listedSupportMember,i){

            let index = this.supportMembersList.findIndex((member) => member.id == -1);

            if(index != -1){

                this.supportMembersList.splice(index,1); 

            }

            if(!this.supportMemberSaved){

                let tempIndex = this.supportMembersList.findIndex(x=>x.id == this.tempPreviewSupportMember.id);

                if(tempIndex != -1){

                    this.supportMembersList[tempIndex] = this.tempPreviewSupportMember;

                }

            }

            this.resetImageValidations();

            if(this.$refs.previewFileInput){

                this.$refs.previewFileInput.value = '';

            }

            let member = listedSupportMember;

            this.previewForm = member;

            if(this.previewForm.available_from){

                const time = new Date(`1970-01-01 ${listedSupportMember.available_from}`);

                // Format the time using the desired format
                const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                this.previewForm.available_from = formattedTime;

                let availableFromTime = this.previewForm.available_from.split(" ");

                this.previewForm.available_from = availableFromTime[0];

            }

            if(this.previewForm.available_to){

                const time = new Date(`1970-01-01 ${listedSupportMember.available_to}`);

                const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                this.previewForm.available_to = formattedTime;

                let availableFromTime = this.previewForm.available_to.split(" ");

                this.previewForm.available_to = availableFromTime[0];

            }

            this.previewForm.index = i;

            this.previewForm.image_src = null;
            this.previewForm.image = member.image;

            this.checkedItems = [];

            this.tempPreviewSupportMember = _.cloneDeep(member);

        },
        closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    let index = this.supportMembersList.findIndex((member) => member.id == -1);
                                
                    if(index != -1){
            
                        this.supportMembersList.splice(index, 1);
            
                    }
            
                    if(!this.supportMemberSaved){
            
                        let tempIndex = this.supportMembersList.findIndex(x=>x.id == this.tempPreviewSupportMember.id);
                        
                        if(tempIndex != -1){

                            this.supportMembersList[tempIndex] = this.tempPreviewSupportMember;

                        }

                    }
                    
                    this.previewForm.id = '';

                    // this.previewForm = _.cloneDeep(this.defaultPreviewForm);
    
                    this.tempSupportMember = _.cloneDeep(this.defaultPreviewForm);
    
                    this.tempPreviewSupportMember = _.cloneDeep(this.defaultPreviewForm);
    
                    this.supportMemberSaved = false;
    
                    this.contactFieldTouched = false;
    
                    this.$refs.supportMemberFormObserver.reset();

                }).catch(() => {})

            }else{

                let index = this.supportMembersList.findIndex((member) => member.id == -1);
                                
                if(index != -1){
        
                    this.supportMembersList.splice(index, 1);
        
                }
        
                if(!this.supportMemberSaved){
        
                    let tempIndex = this.supportMembersList.findIndex(x=>x.id == this.tempPreviewSupportMember.id);

                    if(tempIndex != -1){

                        this.supportMembersList[tempIndex] = this.tempPreviewSupportMember;

                    }
        
                }

                this.previewForm.id = '';

                // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                this.tempSupportMember = _.cloneDeep(this.defaultPreviewForm);

                this.tempPreviewSupportMember = _.cloneDeep(this.defaultPreviewForm);

                this.supportMemberSaved = false;

                this.contactFieldTouched = false;

                this.$refs.supportMemberFormObserver.reset();

            }

        },
        resetPreview(){

            let index = this.supportMembersList.findIndex((member) => member.id == -1);
                                
            if(index != -1){
    
                this.supportMembersList.splice(index, 1);
    
            }
    
            this.previewForm.id = '';

            // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.tempSupportMember = _.cloneDeep(this.defaultPreviewForm);

            this.tempPreviewSupportMember = _.cloneDeep(this.defaultPreviewForm);

            this.supportMemberSaved = false;

            this.contactFieldTouched = false;

            this.$refs.supportMemberFormObserver.reset();

        },
        createSupportMember(){

            if(this.supportMembersLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "Support members are being retrieved.",
                });

                return

            }

            if(this.supportMemberIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "A support member is currently being processed.",
                });

                return

            }

            let index = this.supportMembersList.findIndex(x=>x.id == -1);

            if(index != -1){

                // this.$notify({
                //   type: "error",
                //   title: "Can't Add New Support Member",
                //   message: "you have an unsaved support member in the list",
                // });

                return
            }

            this.closePreview();

            this.resetImageValidations();

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.previewForm.id = -1;

            let tempMember = this.previewForm;

            this.supportMembersList.unshift(tempMember);

            this.checkedItems = [];

            this.$refs.supportMemberFormObserver.reset();

        },
        //preview form open/close & reset end

        //add/update & delete support member start
        validate(){

            this.$refs.supportMemberFormObserver.validate().then(success => {

                if(!success){

                    const errors = Object.entries(this.$refs.supportMemberFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                    this.$refs.supportMemberFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    if(this.previewForm.platform == 'whatsapp' && (typeof this.selectedContactObj.valid == 'undefined' || !this.selectedContactObj.valid)){

                        this.contactIsValid = false;
                        this.contactFieldTouched = true;

                        document.querySelector("#support-member-number").scrollIntoView({ behavior: 'smooth',block: "center" });

                    }

                    if(errors.length > 0){

                        if(this.previewForm.id != -1 && !this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                        }else if(this.previewForm.id == -1 && !this.previewForm.addImage){

                            this.imageValidations.imgIsRequired = true;

                        }

                    }else{

                        if(!this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#support-member-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }

                    }

                }else{

                    if(this.previewForm.platform == 'whatsapp' && (typeof this.selectedContactObj.valid == 'undefined' || !this.selectedContactObj.valid)){

                        this.contactIsValid = false;
                        this.contactFieldTouched = true;

                    }

                    if(this.previewForm.platform == 'whatsapp'){

                        if(this.contactIsValid && this.countryIsSelected){

                            if(this.previewForm.id != -1 && !this.previewForm.image){

                                this.imageValidations.imgIsRequired = true;

                                document.querySelector("#support-member-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                            }else if(this.previewForm.id == -1 && !this.previewForm.addImage){

                                this.imageValidations.imgIsRequired = true;

                                document.querySelector("#support-member-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                            }else{

                                if(this.previewForm.id == -1){

                                    this.addSupportMemberHandle();

                                }else{

                                    this.updateSupportMemberHandle();

                                }

                            }

                        }else{

                            document.querySelector("#support-member-number").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }

                    }else{

                        if(this.previewForm.id != -1 && !this.previewForm.image){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#support-member-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }else if(this.previewForm.id == -1 && !this.previewForm.addImage){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#support-member-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }else{

                            if(this.previewForm.id == -1){

                                this.addSupportMemberHandle();

                            }else{

                                this.updateSupportMemberHandle();

                            }

                        }

                    }

                }

            });

        },
        async addSupportMemberHandle(){

            let formData = new FormData();

            formData.append('name',this.previewForm.name);
            formData.append('designation',this.previewForm.designation);
            formData.append('platform',this.previewForm.platform);

            if(this.previewForm.platform == 'whatsapp'){

                formData.append('number',this.previewForm.number.replace(/\s/g,''));

            }

            if(this.previewForm.platform == 'messenger'){

                formData.append('platform_id',this.previewForm.platform_id);

            }

            if(this.previewForm.addImage_src){

                formData.append('image',this.previewForm.addImage);

            }

            if(this.previewForm.available_from){

                const time = new Date(`1970-01-01 ${this.previewForm.available_from}`);

                // Format the time using the desired format
                const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                formData.append('available_from',formattedTime);

                // // Parse time string into a Date object
                // const date = new Date(`1970-01-01T${this.previewForm.available_from}:00`);

                // // Extract hours (using 12-hour format), minutes, and AM/PM
                // const hours = date.getHours() % 12 || 12;  // Handle 00:00 as 12 AM
                // const minutes = date.getMinutes().toString().padStart(2, '0');
                // const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

                // formData.append('available_from',`${hours}:${minutes} ${ampm}`);

            }

            if(this.previewForm.available_to){

                const time = new Date(`1970-01-01 ${this.previewForm.available_to}`);

                // Format the time using the desired format
                const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                formData.append('available_to',formattedTime);

                // // Parse time string into a Date object
                // const date = new Date(`1970-01-01T${this.previewForm.available_to}:00`);

                // // Extract hours (using 12-hour format), minutes, and AM/PM
                // const hours = date.getHours() % 12 || 12;  // Handle 00:00 as 12 AM
                // const minutes = date.getMinutes().toString().padStart(2, '0');
                // const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

                // formData.append('available_to',`${hours}:${minutes} ${ampm}`);

            }

            this.supportMemberIsSaving = true;
            try{
                let res = await this.$axios.post('/chat-support/add',formData);
                if(res.data.status_code == "1271"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.$store.commit('support_members_module/add_support_member_list',res.data.team_member);

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.supportMemberSaved = true;

                    this.resetPreview();

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.supportMemberIsSaving = false;

            }

        },
        async updateSupportMemberHandle(){

            let formData = new FormData();

            formData.append('id',this.previewForm.id);
            formData.append('name',this.previewForm.name);
            formData.append('designation',this.previewForm.designation);
            formData.append('platform',this.previewForm.platform);

            if(this.previewForm.platform == 'whatsapp'){

                formData.append('number',this.previewForm.number.replace(/\s/g,''));

            }

            if(this.previewForm.platform == 'messenger'){

                formData.append('platform_id',this.previewForm.platform_id);

            }

            if(this.previewForm.image_src){

                formData.append('image',this.previewForm.image);

            }

            if(this.previewForm.available_from){

                const time = new Date(`1970-01-01 ${this.previewForm.available_from}`);

                // Format the time using the desired format
                const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                formData.append('available_from',formattedTime);

                // // Parse time string into a Date object
                // const date = new Date(`1970-01-01T${this.previewForm.available_from}:00`);

                // // Extract hours (using 12-hour format), minutes, and AM/PM
                // const hours = date.getHours() % 12 || 12;  // Handle 00:00 as 12 AM
                // const minutes = date.getMinutes().toString().padStart(2, '0');
                // const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

                // formData.append('available_from',`${hours}:${minutes} ${ampm}`);

            }

            if(this.previewForm.available_to){

                const time = new Date(`1970-01-01 ${this.previewForm.available_to}`);

                // Format the time using the desired format
                const formattedTime = time.toLocaleTimeString('en-US', { hour12: false });

                formData.append('available_to',formattedTime);

                // // Parse time string into a Date object
                // const date = new Date(`1970-01-01T${this.previewForm.available_to}:00`);

                // // Extract hours (using 12-hour format), minutes, and AM/PM
                // const hours = date.getHours() % 12 || 12;  // Handle 00:00 as 12 AM
                // const minutes = date.getMinutes().toString().padStart(2, '0');
                // const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

                // formData.append('available_to',`${hours}:${minutes} ${ampm}`);

            }

            this.supportMemberIsSaving = true;
            try{
                let res = await this.$axios.post('/chat-support/update',formData);
                if(res.data.status_code == "1272"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    let teamMember = _.cloneDeep(res.data.team_member);

                    let memberIndex = this.supportMembersList.findIndex((member) => member.id == res.data.team_member.id);

                    this.supportMembersList[memberIndex] = res.data.team_member;

                    let payload = {
                        index: memberIndex,
                        updatedMember: teamMember
                    }

                    this.$store.commit('support_members_module/update_support_member_list',payload);

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    if(this.previewForm.id == res.data.team_member.id){

                        this.supportMemberSaved = true;

                        this.closePreview();

                    }

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.id[0],
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.supportMemberIsSaving = false;

            }

        },
        deleteSupportMemberHandle(listedSupportMember,index){

            if(!this.tourIsActive){
                
                MessageBox.confirm(
                    "Are you sure you want to delete the expense?", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    this.deletingItems.push(listedSupportMember.id);
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The support member is being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.delete(`/chat-support/remove/${listedSupportMember.id}`);
                        if(res.data.status_code == "1273"){

                            this.$notify({
                                type: "success",
                                title: "Success",
                                message: res.data.message,
                            });

                            // this.$store.commit('support_members_module/delete_support_member_list',index);

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            this.$store.commit('gallery_module/CLEAR_MODULE');

                            //removing deleted item from checked items list
                            this.checkedItems = this.checkedItems.filter((item) => {

                                if(item != listedSupportMember.id){
                                    
                                    return item;
                                    
                                }

                            });

                            this.supportMembersLoading = true;

                            this.$store.dispatch('support_members_module/fetchStoreSupportMembersRequest',{size: 10, page: 1, loadData: 'new'});

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.id){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.error.id[0],
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }


                }).catch(() => {})
            
            }

        },
        //add/update & delete support member end

        //bulk action start
        chatSupportBulkActionHandle(action){

            this.resetPreview();

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                if(action == 'delete'){
                
                    let formData = new FormData();

                    formData.append('resource_type', 'support_member');
                    formData.append("resource_ids", JSON.stringify(this.checkedItems));
                    formData.append("action", action);

                    this.deletingItems = _.cloneDeep(this.checkedItems);
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The selected support members are being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.post('/bulk-action',formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: 'Support members have been successfully removed.',
                            });

                            // let payload = {
                            //     action: 'delete',
                            //     listIds: this.checkedItems
                            // }

                            // this.$store.commit('support_members_module/support_member_bulk_action',payload);

                            this.checkedItems = [];
                            this.deletingItems = [];
                            this.deleteLoader = false;

                            this.supportMembersLoading = true;

                            this.$store.dispatch('support_members_module/fetchStoreSupportMembersRequest',{size: 10, page: 1, loadData: 'new'});

                        }
                    }catch(error){

                        this.deletingItems = [];
                        this.deleteLoader = false;

                        if(error.response){

                            if(error.response.data.message){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: "One of the support members may have been deleted or isn't available.",
                                });

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                    }

                }

            }).catch(() => {})

        },
        //bulk action end

        //search support member start
        searchSupportMember(keyword){
            
            this.$refs.module_search.searchedKeyword = keyword
            
            this.$refs.module_search.search();
            
        },
        //search support member end

        //mobile no. validation methods start
        setMobileNoFieldValidations(validations){

            this.contactIsValid = validations.isContactValid;
            this.countryIsSelected = validations.isCountrySelected;
            this.contactFieldTouched = validations.isFieldTouched;
            this.selectedContactObj = validations.contactObj;

            this.previewForm.number = validations.addContactNo;

        },
        //mobile no. validation methods end

        //other methods start
        checkTimeFormat(){

            let available_from = new Date(`1970-01-01T${this.previewForm.available_from}:00`);
            let available_to = new Date(`1970-01-01T${this.previewForm.available_to}:00`);

            if(available_to < available_from){

                this.$notify({
                    type: 'warning',
                    title: 'Alert',
                    message: "Can't set available from date before available to date",
                });

                this.previewForm.available_to = ''

                return

            }

        },
        async changeAddImage(e){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    this.imageValidations.imgIsRequired = false;

                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = '';

                    }

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                    
                    this.previewForm.addImage = file;
                    this.previewForm.addImage_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                    if(this.$refs.addPreviewFileInput){

                        this.$refs.addPreviewFileInput.value = '';

                    }

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.addImage){

                        this.imageValidations.imgIsRequired = true;

                    }

                    // this.imageValidations.isNotImg = true

                }

            }

        },
        async handleChangeEditImage(e){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    if(this.$refs.previewFileInput){

                        this.$refs.previewFileInput.value = '';

                    }

                    this.imageValidations.imgIsRequired = false;

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.previewForm.image = file;
                    this.previewForm.image_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    if(!this.previewForm.image){

                        this.imageValidations.imgIsRequired = true;

                    }

                    // this.imageValidations.isNotImg = true

                }

            }

        },
        resetImageValidations(){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

        },
        generateUrl(listedSupportMember){
            
            if(listedSupportMember.image){

                if(typeof(listedSupportMember.image) == "string"){

                    return this.base_image_url + this.settings.store_id + '/support/' + listedSupportMember.image;

                }else{

                    return listedSupportMember.image_src;

                }

            }
            
        },
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        checkedItemHandle(vendorId){

            if(!this.tourIsActive && vendorId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == vendorId);

                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(vendorId);

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.supportMembersList.length) ||
                (this.checkedItems.length == this.supportMembersList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.supportMembersList.length){

                    this.checkedItems = [];
                    
                    this.supportMembersList.forEach(vendor => {

                        if(vendor.id != -1){

                            this.checkedItems.push(vendor.id);

                        }

                    });

                }

            }

        },
        clearSelection(){

            MessageBox.confirm(
                'Do you really want to uncheck the selected records?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async () => {

                this.checkedItems = [];

            }).catch(() => {})

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        chatSupportKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        //other methods end

        //tour methods start
        startChatSupportTour(){

            this.$tours.chatSupportTour.start();

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.resetPreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                this.createSupportMember();

            }

            if(currentStep + 1 == 18){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep + 1 == 19){

                this.expand();

            }

        },
        handlePreviousStep(currentStep){
            
            if(currentStep == 7){

                this.resetPreview();

            }

            if(currentStep == 18){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 19){

                if(!this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

        },
        handleTourStop(){

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            this.resetPreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            this.resetPreview();

            this.tourIsActive = false;

        },
        //tour methods end

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
    mounted(){

        this.$refs.supportTable.addEventListener('scroll', this.handleScrollEvent);

        window.addEventListener('keydown',this.chatSupportKeyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.supportMembersList.forEach((member) => {

                if(member.id == -1){

                    index = this.supportMembersList.indexOf(member);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        //master search
        if(this.$route.query.search){
      
            this.$refs.module_search.searchedKeyword = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true;

            }

            this.$router.replace({'query': null});

            this.$refs.module_search.search();

        }

    },
    beforeMount(){

        this.supportMembersLoading = true;
        try{
            if(!this.storedSupportMembers || this.storedSupportMembers.length == 0){

                this.$store.dispatch('support_members_module/fetchStoreSupportMembersRequest',{size: 10, page: 1, loadData: 'new'});

            }else{

                this.supportMembersList = _.cloneDeep(this.storedSupportMembers);
                this.supportMembersLoading = false;
                                
            }

        }catch(error){

            this.supportMembersLoading = false;

            this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener('keydown', this.chatSupportKeyEvent);

    },
    beforeRouteLeave(to, from, next){

        let index = null;

        this.supportMembersList.forEach((member) => {

            if(member.id == -1){

                index = this.supportMembersList.indexOf(member);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{})

        }else{

            next();

        }

    },
    
}
</script>

<style>

</style>